import PartySocket, { PartySocketOptions } from "partysocket";
import { PartyWorksEventSource } from "./lib/EventSource";
import { InternalEvents } from "./types";

interface BaseUser {
  userId: string;
  presence?: any;
}

interface Self extends BaseUser {}

interface Peer extends BaseUser {
  //.. they can have multiple props as well as presence api is must
}

//we need to provide 2 things
//one interface for emiting events, this can lead to duplicate stuff, but can be easily extedmed with a base
//other interface is for listening events

//this is room

//* OTHERS  [option of filter, immutable ref]
//* SELF [getPresence, updatePresence, getOthers, getSelf, getStatus]
//* SUBSCRIE TO UPDATES [ boradcast, myPresence,  others, status,  ]
//* STATUS [connect, ]
//* BROADCAST API [ broadcastEvent]
//* CUSTOM EVENTS API

//i think for the internal events we can have rpc based format, but custom events can be tricky the ones that don't follow req/res format
type InternalEventsMap =
  | {
      event: InternalEvents.CONNECT;
      data: { id: string };
    }
  | { event: InternalEvents.ROOM_STATE; data: { users: Peer[] } }
  | { event: InternalEvents.USER_JOINED; data: { userId: string } }
  | { event: InternalEvents.USER_LEFT; data: { userId: string } }
  | {
      event: InternalEvents.PRESENSE_UPDATE;
      data: { userId: string; data: any };
    }
  | { event: InternalEvents.BROADCAST; data: any };

export enum InternalListeners {
  Message = "message",
  SelfUpdate = "selfUpdate",
  RoomUpdate = "roomUpdate",
  UserJoined = "userJoined",
  UserLeft = "userLeft",
}

export interface InternalListenersMap<T = any> {
  message: MessageEvent<any>;
  selfUpdate: {};
  roomUpdate: { data: { others: Peer[] } };
  userJoined: { peer: Peer };
  userleft: { peer: Peer };
  peersUpdate: {};
  presenceUpdate: T;
  broadcast: any;
}

export class PartyWorksClient<
  T extends Record<string, any> & InternalListenersMap<K>,
  K = any
> extends PartyWorksEventSource<T> {
  _partySocket: PartySocket;
  _loaded: boolean = false; //we count that we're still connecting if this is not laoded yet
  _self: Peer; //not sure how to structure this one?
  _peers: Peer[] = [];

  constructor(options: PartySocketOptions) {
    super();
    this._partySocket = new PartySocket({ ...options, startClosed: true });
    // this._partySocket.reconnect();
    this._message();
  }

  _message() {
    //for better typescript support
    // const emit = this.emit as PartyWorksClient<InternalListenersMap>["emit"];

    this.on(InternalListeners.Message, (data) => {});
    this._partySocket.addEventListener("message", (e) => {
      //this handler is always called, as it is a basic all message event handler
      this.emit(InternalListeners.Message, e);

      const parsedData = JSON.parse(e.data);

      if (!parsedData || typeof parsedData.event === "undefined") {
        //   this should never happen
        console.error(`No event field in the response from websocket`);
        return;
      }

      //differentiating internal events from external ones

      //these are internal events
      if (
        Object.values(InternalEvents).includes(
          parsedData.event as InternalEvents
        )
      ) {
        const data = parsedData as InternalEventsMap;

        switch (data.event) {
          case InternalEvents.CONNECT: {
            this._self = { userId: data.data.id };
            this.emit("selfUpdate", {});

            break;
          }

          case InternalEvents.ROOM_STATE: {
            this._peers = data.data.users;
            this._loaded = true;
            this.emit("roomUpdate", { data: { others: this._peers } });
            break;
          }

          case InternalEvents.USER_JOINED: {
            this._peers.push(data.data);
            this.emit("userJoined", { peer: data.data });
            break;
          }

          case InternalEvents.USER_LEFT: {
            this._peers = this._peers.filter(
              (peer) => peer.userId !== data.data.userId
            );
            this.emit("userleft", { peer: data.data });
            break;
          }

          case InternalEvents.PRESENSE_UPDATE: {
            this._peers = this._peers.map((peer) => {
              if (peer.userId === data.data.userId) {
                return {
                  ...peer,
                  //ahahaha taking a moment to laugh  data.data.data lol ><
                  presence: data.data.data, // Update the presence property
                };
              }
              return peer; // Keep other peers unchanged
            });

            this.emit("peersUpdate", {});
            console.log(data);
            break;
          }

          case InternalEvents.BROADCAST: {
            console.log(`ccoming`);
            console.log(data.data);
            this.emit("broadcast", {});
            break;
          }

          default: {
            console.error(`unknown evemt`);
          }
        }
        return;
      }

      //if we don't have a registered listener for this event

      if (!this.events[parsedData.event as keyof InternalListenersMap]) {
        return;
      }

      for (let cb of this.events[
        parsedData.event as keyof InternalListenersMap
      ]) {
        cb.exec(parsedData);
      }
    });
  }

  updatePresence(data: K) {
    if (this._self) {
      this._self.presence = data;
      this._partySocket.send(
        JSON.stringify({ event: InternalEvents.PRESENSE_UPDATE, data })
      );

      this.emit("presenceUpdate", data);
    }
  }

  broadcast(data: any) {
    this._partySocket.send(
      JSON.stringify({ event: InternalEvents.BROADCAST, data })
    );
  }

  getOthers(): Peer[] {
    return this._peers;
  }

  getSelf(): Self {
    return this._self;
  }
}
