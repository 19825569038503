import { InternalListenersMap, PartyWorksClient } from "./PartyWorksClient";

declare const PARTYKIT_HOST: string;

type Presence = {
  randomNumber: number;
};

interface EventListeners extends InternalListenersMap<Presence> {}

console.log(`this is client bob!!!!!`);
const room = new PartyWorksClient<EventListeners, Presence>({
  host: PARTYKIT_HOST,
  room: "my-new-room",
});

// conn.on("message", (e) => {
//   try {
//     const data = JSON.parse(e.data as string);
//     switch (data.event) {
//       case InternalEvents.CONNECT: {
//         //not sure what this has to do, but maye this & roomState can be used to detect if the connected is completely loaded
//         //this can be a preloading state? even after the websocket connection, dunno :/
//         console.log(`got a connect`, data.data);

//         break;
//       }

//       case InternalEvents.ROOM_STATE: {
//         console.log(`got room state`, data.data);
//         break;
//       }

//       case InternalEvents.USER_JOINED: {
//         console.log(`got user joined`, data.data);
//         break;
//       }

//       case InternalEvents.USER_LEFT: {
//         console.log(`got user left`, data.data);
//         break;
//       }

//       //now think of a way to add custojme events here

//       default: {
//         console.log(`unknown event`);
//         console.log(e);
//       }
//     }
//   } catch (error) {}
// });

// conn.on("",() => {

// })

setInterval(() => {
  room.updatePresence({ randomNumber: Math.floor(Math.random() * 99) });
}, 3000);

room.on("userJoined", (data) => {
  console.log(data);

  console.log(`a new use joined ${data.peer.userId}`);
  console.log(`peers`);
  console.log(room.getOthers());
});

room.on("userleft", () => {
  console.log(room.getOthers());
});

room.on("presenceUpdate", (presence) => {});

document.getElementsByTagName("body")[0].addEventListener("click", () => {
  room.broadcast({ msg: "hehehehehe" });
});
